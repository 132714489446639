<template>
  <v-container fluid>
    <TheTitle :text="title" icon="mdi-sitemap"> 
      <v-row justify="start">
        <v-col>
          <v-select
            dense
            hide-details
            solo
            label="Selecione o setor desejado"
            :items="sectors"
            v-model="bySector"
            item-value="id"
            item-text="name"
            outlined
            flat
            @change="loadBySector()"
          ></v-select>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card v-if="showChart" class="pa-4 myclass">
      <div
        ref="chart"
        :style="{ width: computedWidth + 'px', height: divHeigth + 'px' }"
      ></div>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from '@/components/TheTitle'
import * as echarts from 'echarts'

export default {
  computed: {
    computedWidth () {
      return this.maxChildrenCount > 5 ? this.widthMax : '100%'
    }
  },
  components: {
    TheTitle
  },
  data: () => ({
    title: 'Organograma Hierárquico',
    baseRoute: 'position',

    result: [],
    objects: [],
    bySector: [],
    sectors: [],
    resultBySector: [],
    maxChildrenCount: [],
    
    depth: 1,
    widthMax: 0,
    showChart: false,
    chartHeigth: 0,
    divHeigth: 0
    
  }),
  methods: {
    loadData () {
      const url = `${baseApiUrl}/${this.baseRoute}`
      this.$store.dispatch('setLoadingInfo', true)
      axios
        .get(url)
        .then(res => {
          this.objects = res.data
          this.$store.dispatch('setLoadingInfo', false)
        })
        .catch(showError)
        .finally(() => {
          this.$store.dispatch('setLoadingInfo', null)
        })
    },
    filteredSector (sectorId) {
      let sector = this.sectors.filter(value => value.id === sectorId)
      if (sector[0]) {
        sector = sector[0]
        return sector
      }
      return
    },
    async loadSectors () {
      const url = `${baseApiUrl}/sector`
      return await axios
        .get(url)
        .then(res => {
          this.sectors = res.data
          return true
        })
        .catch(showError)
    },
    contarChildren (obj) {
      let maxChildren = 0

      if (Array.isArray(obj)) {
        obj.forEach(item => {
          if (item.children.length > 0) {
            this.depth += 1
            console.log(this.depth)
          }
        })
      }

      if (Array.isArray(obj)) {
        for (const item of obj) {
          if (item.children.length > maxChildren) {
            maxChildren = item.children.length
          }

          if (item.children && item.children.length > 0) {
            const maxForThisChild = this.contarChildren(item.children)
            if (maxForThisChild > maxChildren) {
              maxChildren = maxForThisChild
            }
          }
        }
      } else if (obj && obj.children) {
        if (obj.children.length > maxChildren) {
          maxChildren = obj.children.length
        }

        const maxForObj = this.contarChildren(obj.children)
        if (maxForObj > maxChildren) {
          maxChildren = maxForObj
        }
      }

      this.maxChildrenCount = maxChildren
      this.widthMax = 900 + this.maxChildrenCount * 150
      this.divHeigth = 300 + this.depth * 100
      this.chartHeigth = 300 + this.depth * 100
      return maxChildren
    },

    async loadBySector () {
      this.showChart = null
      const url = `${baseApiUrl}/${this.baseRoute}/search/hierarchy/${this.bySector}`
      this.$store.dispatch('setLoadingInfo', true)
      await axios
        .get(url)
        .then(res => {
          this.resultBySector = res.data
          this.$store.dispatch('setLoadingInfo', false)
        })
        .catch(showError)
      if (this.resultBySector !== null && this.resultBySector.length > 0) {
        this.showChart = true
        this.createChart()
      }
    },

    async loadHierarchy () {
      const url = `${baseApiUrl}/${this.baseRoute}/search/hierarchy`
      this.$store.dispatch('setLoadingInfo', true)
      await axios
        .get(url)
        .then(res => {
          this.result = res.data
          this.$store.dispatch('setLoadingInfo', false)
        })
        .catch(showError)
    },
    getChartOption (data) {
      const option = this.createChart()
      option.series[0].data = [data[0]]
      return option
    },
    createChart () {
      const data = this.resultBySector[0]

      const option = {
        dataZoom: {
          type: 'inside',
          moveOnMouseWheel: 'true'
        },
        series: [
          {
            type: 'tree',

            data: [data],

            top: '10%',
            left: '1%',
            bottom: '10%',
            right: '1%',

            symbolSize: 7,
            edgeShape: 'polyline',
            edgeForkPosition: '0%',
            initialTreeDepth: 10,
            lineStyle: {
              width: 4
            },
            orient: 'vertical',

            label: {
              width: 200,
              height: 60,
              backgroundColor: 'white',
              borderWidth: 80,
              borderRadius: 3,
              shadowColor: '#595953',
              shadowBlur: 10,
              shadowOffsetX: 5,
              shadowOffsetY: 5,
              formatter: [
                ['{headLine|}'],
                ['{avatar|}', '{username|{b}}'].join('')
              ].join('\n'),
              rich: {
                headLine: {
                  width: 200,
                  height: 10,
                  backgroundColor:
                  '#306bfd',
                  position: 'absolute',
                  align: 'top'
                },
                username: {
                  align: 'center',
                  padding: [0, 0, 0, 15],
                  verticalAlign: 'top',
                  fontSize: 18,
                  width: 50,
                  height: 50
                },
                userTitle: {
                  align: 'center',
                  padding: [-30, 0, 0, 75],
                  verticalAlign: 'top',
                  color: '#808080',
                  fonntWeight: 10,
                  fontSize: 15,
                  width: 50,
                  height: 50
                }
              }
            }
          }
        ]
      }
      const chart = echarts.init(this.$refs.chart)
      chart.setOption(option)
      if (option) {
        this.showChart = true
      }
      return option
    }
  },
  watch: {
    bySector (newValue) {
      this.depth = 1
      const resultado = this.contarChildren(newValue)
      this.chartHeigth = resultado.chartHeigth
      this.divHeigth = resultado.divHeigth
      if (newValue !== null) {
        this.loadBySector()
      }
    },
    resultBySector: {
      handler (newVal) {
        if (this.showChart && newVal.length > 0) {
          this.depth = 1
          const divHeigth = this.contarChildren(newVal)
          const chart = echarts.getInstanceByDom(this.$refs.chart)
          chart.setOption(this.getChartOption(newVal))
          chart.resize({ height: divHeigth + 'px' })
        }
      },
      immediate: true
    }
  },
  async mounted () {
    this.loadData()
    await this.loadSectors()
    await this.loadHierarchy()
  }
}
</script>

<style>
.custom-spacing {
  height: 150px;
}
.myclass {
  overflow-x: scroll;
  margin: 10px;
  border-radius: 3px;
  padding: 5px;
}

.myclass::-webkit-scrollbar:horizontal {
  display: block;
  height: 6px;
  width: 6px;
}
.myclass::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  margin-left: 0.7vw;
  margin-right: 0.7vw;
}
.myclass::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.myclass::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


</style>
